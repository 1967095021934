import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Monitor your Uptime and alert users when something goes wrong.
Use Crisp for free
(No credit card required)
Inform users with a public status page
Create a public status page using Crisp to monitor your uptime and let customers see what is going on with your system. Crisp will monitor downtimes and alert users when something goes down.`}</p>
    <p>{`This way you can focus on the problem resolution instead of being flooded by customers.`}</p>
    <p>{`Monitor your system status in real time
Connect your servers to the Crisp Status page and we’ll send you notifications to your email and to Slack when something goes down.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      